<template>
  <v-menu
    bottom
    origin="center center"
    transition="scale-transition"
    v-if="
      (counts.countDelivery && counts.countDelivery.length > 0) ||
      (counts.countWarehouseState && counts.countWarehouseState.length > 0)
    "
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon color="primary" v-bind="attrs" v-on="on"
        >mdi-dots-vertical</v-icon
      >
    </template>

    <v-list min-width="260px" class="px-2">
      <table style="font-size: 12px; width: 100%">
        <tbody>
          <tr>
            <td
              colspan="3"
              class="text-center error--text font-weight-medium fs-14"
            >
              {{ $t("labels.classify") }}
            </td>
          </tr>
          <tr
            v-for="(item, i) in counts.countDelivery"
            :key="`cbt_${i}`"
            class="text-right"
          >
            <td class="pa-1">
              {{ $t(`labels.zalo_sent_status_${item.sent_status}`) }}
            </td>
            <td style="min-width: 60px" class="pa-1">
              <b class="blue--text">{{ formatNumber(item.count_id) }}</b>
              {{ $t("labels.message") }}
            </td>
            <td style="min-width: 30px" class="pa-1">
              <b class="blue--text">
                {{ calPercent(item, counts.countDelivery) }}
              </b>
              %
            </td>
          </tr>
          <tr>
            <td
              colspan="3"
              class="text-center error--text font-weight-medium fs-14 pt-3"
            >
              {{ $t("labels.warehouse_status") }}
            </td>
          </tr>
          <tr
            v-for="(item, i) in counts.countWarehouseState"
            :key="`cbd_${i}`"
            class="text-right"
          >
            <td class="pa-1">
              {{ $t(`labels.order_status_${item.state_goods_issue_detail}`) }}
            </td>
            <td class="pa-1">
              <b class="blue--text">{{ formatNumber(item.count_id) }}</b>
              {{ $t("labels.message") }}
            </td>
            <td class="pa-1">
              <b class="blue--text">{{
                calPercent(item, counts.countWarehouseState)
              }}</b>
              %
            </td>
          </tr>
        </tbody>
      </table>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "ZnsCount",
  props: {
    counts: {
      type: [Array, Object],
      default: () => {},
    },
  },
  methods: {
    calPercent(item, arr) {
      let total = 0;
      for (let i = 0; i < arr.length; i++) {
        const aItem = arr[i];
        total += aItem.count_id;
      }
      const percent = (item.count_id / total) * 100;
      return this.formatNumber(percent, 2);
    },
  },
};
</script>
